<template>
  <button class="simple-btn" @click="handler">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'MyBtn',
  methods: {
    handler() {
      this.$emit('btnClicked')
    }
  }
}
</script>

<style scoped></style>
